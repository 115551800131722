import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  formModel = {
    UserName: '',
    Password: ''
  }

  userName: string;

  displaySending: boolean = false;

  constructor(private service: UserService, private router: Router) { }

  ngOnInit() {
    //console.log(localStorage.getItem('token-jakemp'));
    //if (localStorage.getItem('token-jakemp') != null)
    //  this.router.navigateByUrl('/home');
  }

  onSubmit() {
    this.displaySending = true;
    this.service.login(this.formModel).subscribe(
      (res: any) => {
        this.displaySending = false;
        setTimeout(() => {
          if (res.message == 'failed') {
            this.router.navigate(['/home']);
          } else {
            localStorage.setItem('token-jakemp', res.token);
            this.service.setUser(res.userName);

            console.log("Username " + res.userName);


            this.service.setToken(res.token);
            this.service.setIsAuthenticated(true);

            var payLoad = JSON.parse(window.atob(localStorage.getItem('token-jakemp').split('.')[1]));
            var userRole = payLoad.role;
            this.service.setUserRoles(userRole);

            //console.log(JSON.parse(window.atob(localStorage.getItem('token-jakemp').split('.')[2])));

            //for (var i = 0; i < res.role.length; i++) {
            //  this.service.setUserRoles(res.role[i]);
            //  console.log(res.role[i]);
            //}

            this.router.navigateByUrl('/news');
          }
        }, 900);
        console.log(res);

      },
      err => {
        if (err.status == 400)
          console.log('Incorrect username or password.', 'Authentication failed.');
        else
          console.log(err);
      }
    );
  }
}
