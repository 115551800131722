import { Component, OnInit } from '@angular/core';
import { ApiDataService } from '../services/api-data.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userName: string;
  oldPassword: string;
  newPassword: string;

  //Sending modal
  displaySending: boolean = false;
  sendStatus: string = "";

  constructor(private apiDataService: ApiDataService, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.userService.isAuthenticatedEmitted$.subscribe(
      data => {
        if (data == false) {
          this.router.navigate(['/home']);
        };
      }
    );

    this.userService.userEmitted$.subscribe(
      data => {
        if (data) {
          this.userName = data;
        };
      }
    );
  }

  changePassword() {
    this.displaySending = true;
    this.sendStatus = "sending";
    this.apiDataService.changePassword(this.userName, this.oldPassword, this.newPassword).subscribe(
      data => {
        if (data.message == "success") {
          this.sendStatus = "complete"
        }
        if (data.message == "failed") {
          this.sendStatus = "failed"
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  redirect() {
    this.displaySending = false;
    setTimeout(() => {
      this.router.navigate(['/news']);
    }, 2000);

  }

}
