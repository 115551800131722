import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';
import { saveAs } from 'file-saver';
import { map, filter, switchMap } from 'rxjs/operators';
import { SelectItem } from '../interfaces/select-item';
import { ISystemFile } from '../interfaces/system-file';
import { FilesService } from '../services/files.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-file-content',
  templateUrl: './file-content.component.html',
  styleUrls: ['./file-content.component.css']
})
export class FileContentComponent implements OnInit {

  apiUrl: string;
  httpClient: HttpClient;
  files: SelectItem[];
  selectedFile: string;
  uploadedFiles: any[] = [];

  errorMessage: string;
  fileList: ISystemFile[] = [];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _FilesService: FilesService, private userService: UserService, private router: Router) {

    this.apiUrl = baseUrl;
    this.httpClient = http;
  }

  ngOnInit() {
    this.userService.isAuthenticatedEmitted$.subscribe(
      data => {
        if (data == false) {
          this.router.navigate(['/home']);
        };
      }
    );

    this.getFiles();
  }

  public getFiles() {
    this.errorMessage = "";
    //Clear Filelist
    this.fileList = [];
    // Call the service
    this._FilesService.getFiles()
      .subscribe((files) => {
        for (let file of files) {
          this.fileList.push({
            fileName: file.fileName,
            filePath: file.filePath,
            lastModifiedDate: file.lastModifiedDate
          });
        }
      },
        error => this.errorMessage = <any>error);
  }

  onBeforeUpload(event) {
    console.log(event);
  }

  public onUpload(event) {
    console.log(event.files);
    this.getFiles();
  }
  public deleteFile(file: ISystemFile) {
    // Call the service
    this._FilesService.deleteFile(file)
      .subscribe((response) => {
        // Refresh file list
        this.getFiles();
      },
        error => this.errorMessage = <any>error);
  }

  download(file: ISystemFile) {

    let fileName = file;
    let checkFileType = fileName.fileName.split('.').pop();
    var fileType;
    if (checkFileType == ".txt") {
      fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == ".doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "docx") {
      //fileName.fileName = fileName.fileName.split('.').shift();

      fileType = "application/msword";
    }
    if (checkFileType == ".xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == ".png") {
      fileType = "image/png";
    }
    if (checkFileType == "jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".gif") {
      fileType = "image/gif";
    }
    if (checkFileType == ".csv") {
      fileType = "text/csv";
    }

    console.log(fileName.fileName);

    this._FilesService.downloadFile(file, fileType).subscribe(blob => {
      if (fileType != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        saveAs(blob, file.fileName, {
          type: fileType
        });
      } else {
        var binaryString = window.atob(blob);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        //-------

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([bytes], { type: 'application/octet-stream' }));

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), fileName.fileName);
        }

        link.download = fileName.fileName;
        link.click();
      }

    },
      (err) => {
        console.log(err);
      }
    );
  }

  //  this.httpClient.post(this.apiUrl + 'api/FileContent/DownloadDoc', { responseType: 'blob' }).subscribe(blob => {
  //    saveAs(blob, 'download.jpg', {
  //      type: 'image/jpg' // --> or whatever you need here
  //    });
  //  },
  //    (err) => {
  //      console.log(err);
  //    }
  //  );
  //}

  //---------------------------

  //onUpload(event) {
  //  for (let file of event.files) {
  //    this.uploadedFiles.push(file);
  //  }
  //}

  //getFiles() {

  //  this.getFilesApi().subscribe(
  //    data => {
  //      this.files = data;
  //      console.log(data);
  //    },
  //    (err) => { console.log(err); }
  //  );
  //}

  //getFilesApi() {
  //  return this.httpClient.get<SelectItem[]>(this.apiUrl + 'api/FileContent/GetFiles')
  //    //.pipe(map((res: Response) => res.json()));
  //}

}
