import { Component, OnInit } from '@angular/core';
import { SelectItem } from '../interfaces/select-item';
import { ApiDataService } from '../services/api-data.service';
import { IApplicationUser } from '../Interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  userTypes: SelectItem[];
  selectedUserType: string;
  password: string;
  returnUrl: string;

  applicationUser: IApplicationUser = {
    userName: '',
    firstName: '',
    lastName: '',
    password: '',
    role: ''
  };

  //Sending modal
  displaySending: boolean = false;
  sendStatus: string = "";

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiDataService: ApiDataService, private userService: UserService) {

    this.userTypes = [
      { label: 'Select User type', value: null },
      { label: 'User', value: 'USER' },
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Super user', value: 'SUPERUSER' }
    ]

  }

  ngOnInit() {
    const action = this.activatedRoute.snapshot.queryParamMap.get('returnUrl'); // this.activatedRoute.snapshot.url["returnUrl"];
    console.log(action);

    this.userService.isAuthenticatedEmitted$.subscribe(
      data => {
        if (data == false) {
          this.router.navigate(['/home']);
        };
      }
    );
  }

  async register() {
    this.displaySending = true;
    this.sendStatus = "sending";
    var getFunc = await this.apiDataService.register(this.applicationUser).subscribe(
      data => {
        if (data) {
          setTimeout(() => {
            this.displaySending = false;
            this.sendStatus = "complete";
            setTimeout(() => {
              this.router.navigate(['/news']);
            }, 1000);
           
          }, 2000);
          
          console.log(data);
        }
      },
      (err) => {
        //this.closeAllModals();
        //this.isError = true;
        //this.errorMessage = "getCurrentUserAsync() " + err;
        console.log("Error register() " + err);
      },
      () => { }
    );
    return getFunc;
  }

}
