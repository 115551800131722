import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {

    this.userService.setIsAuthenticated(false);

    localStorage.removeItem('token-jakemp');
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 2000);

  }

}
