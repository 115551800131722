import { Injectable } from '@angular/core';
import {
  Http, Response, RequestOptions,
  Request, RequestMethod, Headers
} from '@angular/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ISystemFile } from '../Interfaces/system-file';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
@Injectable()
export class FilesService {

  token: any;
  httpOptions: any;

  constructor(private _http: Http, private userService: UserService) {


  }
  getFiles(): Observable<ISystemFile[]> {

    this.userService.tokenEmitted$.subscribe(
      data => {
        this.token = data;
      }
    );
    this.httpOptions = {
      headers: new HttpHeaders({

        'Content-Type': 'application/json',
        'Authorization': this.token
      })
    };

    var _Url = 'api/FileContent/SystemFiles';
    return this._http.get(_Url)
      .pipe(map((response: Response) => <ISystemFile[]>response.json()),
      catchError(this.handleError));
  }
  deleteFile(file: ISystemFile): Observable<string> {
    var _Url = 'api/FileContent';
    // This is a Post so we have to pass Headers
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    // Make the Angular 2 Post
    return this._http.post(_Url,
      JSON.stringify(file), options)
      .pipe(map((response: Response) => <string>response.statusText),
      catchError(this.handleError));
  }

  downloadFile(file: ISystemFile, fileExtension): Observable<string> {
    var _Url = 'api/FileContent/DownloadDoc';
    // This is a Post so we have to pass Headers
    let headers = new Headers({ 'Accept': 'application/json',  'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    return this._http.post(_Url,
      JSON.stringify(file), options)
      .pipe(map((response: Response) => <string>response.statusText),
       catchError(this.handleError));
  }
  // Utility
  private handleError(error: Response) {
    // in a real world app, we may send the server to 
    // some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }
}
