import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  roles = new BehaviorSubject<any>('');
  rolesEmitted$ = this.roles.asObservable();

  isAuthenticated = new BehaviorSubject<any>('');
  isAuthenticatedEmitted$ = this.isAuthenticated.asObservable();

  token = new BehaviorSubject<any>('');
  tokenEmitted$ = this.token.asObservable();

  user = new BehaviorSubject<any>('');
  userEmitted$ = this.user.asObservable();

  constructor(private http: HttpClient) { }
  readonly BaseURI = 'https://www.jakempbc.com/api';

  //formModel = this.fb.group({
  //  UserName: ['', Validators.required],
  //  Email: ['', Validators.email],
  //  FullName: [''],
  //  Passwords: this.fb.group({
  //    Password: ['', [Validators.required, Validators.minLength(4)]],
  //    ConfirmPassword: ['', Validators.required]
  //  }, { validator: this.comparePasswords })

  //});

  //comparePasswords(fb: FormGroup) {
  //  let confirmPswrdCtrl = fb.get('ConfirmPassword');
  //  //passwordMismatch
  //  //confirmPswrdCtrl.errors={passwordMismatch:true}
  //  if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
  //    if (fb.get('Password').value != confirmPswrdCtrl.value)
  //      confirmPswrdCtrl.setErrors({ passwordMismatch: true });
  //    else
  //      confirmPswrdCtrl.setErrors(null);
  //  }
  //}

  //register() {
  //  var body = {
  //    UserName: this.formModel.value.UserName,
  //    Email: this.formModel.value.Email,
  //    FullName: this.formModel.value.FullName,
  //    Password: this.formModel.value.Passwords.Password
  //  };
  //  return this.http.post(this.BaseURI + '/ApplicationUser/Register', body);
  //}

  login(formData) {
    return this.http.post(this.BaseURI + '/Login/Login', formData);
  }

  getUserProfile() {
    return this.http.get(this.BaseURI + '/UserProfile');
  }

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token-jakemp').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  //Set global Emits

  setUserRoles(value: any) {
    this.roles.next(value);
  }

  setIsAuthenticated(value: any) {
    this.isAuthenticated.next(value);
  }

  setToken(value: any) {
    this.token.next(value);
  }

  setUser(value: any) {
    this.user.next(value);
  }
}
