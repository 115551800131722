import { Component, OnInit } from '@angular/core';
import { map, filter, switchMap } from 'rxjs/operators';
import { SelectItem } from '../interfaces/select-item';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  content: string;
  uploadedFiles: any[] = [];
  code: number;
  generatedCode: number;
  isFormVerified: boolean = false;

  constructor(private router: Router) {

  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onCodeKeyUp() {
    if (this.code != null) {
      if (this.code == this.generatedCode) {
        this.isFormVerified = true;
      } else {
        this.isFormVerified = false;
      }
    } else {
      this.isFormVerified = false;
    }
  }

  onVerify() {
    if (this.generatedCode == this.code) {
      this.router.navigate(['/news']);
    }
  }

  ngOnInit() {

    this.generatedCode = Math.floor(Math.random() * 900000) + 100000;

    this.content = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porta mattis maximus. In pretium est et pellentesque efficitur.
    Fusce rutrum leo et urna hendrerit, et efficitur elit ultricies.Interdum et malesuada fames ac ante ipsum primis in faucibus.
    Sed iaculis suscipit nulla et venenatis.Integer rhoncus et arcu vitae pellentesque.Vivamus laoreet turpis in velit suscipit porta.
    Duis lobortis massa a felis tristique sollicitudin.Donec ultricies faucibus suscipit.Curabitur condimentum enim porttitor leo auctor ornare.
    Morbi tincidunt quam orci, eu mattis erat blandit ac.Integer sed luctus erat.Curabitur eleifend dolor a nisl volutpat mattis.
    Maecenas sed nisl vitae neque laoreet efficitur.Sed nec magna eget est vehicula efficitur.
    Ut suscipit tristique nulla.Quisque porta metus non urna cursus pulvinar.Quisque fringilla eleifend neque eget convallis.Pellentesque lectus eros,
      scelerisque sit amet eleifend vel, porttitor nec lorem.Vestibulum sed enim tempor, ultrices tortor sed, lacinia lectus.Integer tellus lacus,
      cursus sit amet velit et, pharetra condimentum felis.Donec enim arcu, faucibus ut massa vitae, mollis posuere ligula.Suspendisse ac finibus ligula.
    Quisque at quam aliquam, laoreet tellus ac, ullamcorper est.Fusce sodales rutrum sem et porttitor.Pellentesque maximus diam sit amet nibh suscipit vestibulum.
    Fusce dictum sit amet arcu ac feugiat.Morbi faucibus sapien eu nisi imperdiet, nec consequat lorem tincidunt.Aenean sed fermentum quam.
    Cras eu vulputate turpis.Quisque pretium ante ipsum, at tempus purus euismod at.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Fusce purus mauris, rhoncus et tincidunt sed, suscipit a libero.Duis justo nulla, ultrices non commodo venenatis,
      accumsan a nulla.Mauris iaculis odio sit amet sollicitudin ultrices.In at vulputate ex.Nulla facilisi.Suspendisse et diam ultricies, tincidunt urna in,
      viverra lacus.Maecenas ipsum lacus, iaculis vel mattis id, cursus vitae lorem.Maecenas quis augue vitae tellus porttitor luctus ac id diam.
    Suspendisse ut lectus ultrices, placerat velit a, ultricies quam.Nullam pellentesque eleifend massa, et finibus nisl imperdiet non.Sed tellus purus, tempor non ligula ac,
      viverra rhoncus leo.Etiam scelerisque euismod felis ac placerat.In accumsan erat eget est mollis, id varius purus pellentesque.
    Aliquam imperdiet faucibus metus, ut iaculis arcu auctor at.Quisque sed justo in dui feugiat bibendum nec vitae nulla.Vivamus non arcu sem.Suspendisse quis mollis nisl,
      nec lacinia orci.Phasellus pretium venenatis lacus.Fusce sit amet sem tellus.Aliquam vel congue sapien.
    Fusce lobortis iaculis est, a mollis purus placerat vel.Phasellus vitae fringilla libero.Suspendisse tristique suscipit risus,
      vel ullamcorper ipsum fermentum eget.Sed porttitor dolor at iaculis dapibus.Vivamus urna elit, iaculis vel hendrerit eu,
      commodo ac dui.Ut et tortor et leo rutrum maximus vel volutpat magna.Phasellus commodo sagittis felis, a ultrices lorem pretium vel.Vivamus lobortis in tellus in consectetur.
    Mauris sit amet finibus mauris.Nulla lorem urna, blandit porttitor sagittis in, luctus nec lacus.`;

  }

}
