import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';

//Prime components
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { FileContentComponent } from './file-content/file-content.component';
import { FilesService } from './services/files.service';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';

//import { LoginComponent } from '../api-authorization/login/login.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { AuthGuard } from './services/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { NewsComponent } from './news/news.component';
import { NewsEditorComponent } from './news-editor/news-editor.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    FileContentComponent,
    LoginComponent,
    LogoutComponent,
    NewsComponent,
    NewsEditorComponent,
    RegisterComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'file-content', component: FileContentComponent, canActivate: [AuthGuard], data: { permittedRoles: ['Admin', 'SuperUser'] } },
      { path: 'logout', component: LogoutComponent },
      { path: 'news', component: NewsComponent },
      { path: 'news-editor', component: NewsEditorComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { permittedRoles: ['SuperUser', 'Admin'] }   },
      { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperUser'] } },
      { path: 'register', component: RegisterComponent, canActivate: [AuthGuard], data: { permittedRoles: ['SuperUser'] } },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] }
    ]),
    FileUploadModule,
    ListboxModule,
    TableModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    EditorModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    FilesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
