import { Injectable, Inject } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { map, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  apiUrl: string;

  constructor(private http: Http, @Inject('BASE_URL') baseUrl: string) {
    this.apiUrl = baseUrl + "api/";
  }

  getNewsEdit() {
    return this.http.get(this.apiUrl + 'NewsEditor/')
      .pipe(map((res: Response) => res.json()));
  }

  updateNewsEdit(newsEdit) {
    return this.http.put(this.apiUrl + 'NewsEditor/', newsEdit)
  }

  register(user) {
    return this.http.post(this.apiUrl + 'Login/Register/', user);
  }

  changePassword(userName, oldPassword, newPassword) {
    return this.http.get(this.apiUrl + 'Login/ChangePassword/' + userName + '/' + oldPassword + '/' + newPassword)
      .pipe(map((res: Response) => res.json()));
  }

}
