import { Injectable, Inject } from '@angular/core';
import { Http } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiAuthService {

  apiUrl: string;

  userRole = new BehaviorSubject<any>('');
  userRoleEmitted$ = this.userRole.asObservable();

  constructor(private http: Http, @Inject('BASE_URL') baseUrl: string) {
    this.apiUrl = baseUrl + "api/";
  }

  register(user) {
    return this.http.post(this.apiUrl + 'Login/Register', user);
  }

  login(user) {
    return this.http.post(this.apiUrl + 'Login/Login', user);
  }

   //Set global Emits

  public setUserRoleProperties(object) {
    this.userRole.next(object);
  }

}
