import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiAuthService } from '../../api-authorization/authorization-services/api-auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  //public isAuthenticated: Observable<boolean>;
  //public userName: Observable<string>;
  //public role: Observable<boolean>;

  isAuthenticated: boolean = false;
  roles: string[] = [];
  userName: string;

  constructor(private authorizeService: AuthorizeService, private apiAuthService: ApiAuthService, private userService: UserService) {
  }

  ngOnInit() {

    this.userService.isAuthenticatedEmitted$.subscribe(
      data => {
        this.isAuthenticated = data;
      }
    );

  

    this.userService.rolesEmitted$.subscribe(
      data => {
        this.roles = data;
      }
    );

    this.userService.userEmitted$.subscribe(
      data => {
        this.userName = data;
      }
    );



    //setInterval(() => { 
    //this.isAuthenticated = this.authorizeService.isAuthenticated();
    //  this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));

    //  this.apiAuthService.userRoleEmitted$.subscribe(
    //    data => {
    //      console.log("role " + this.role);
    //      if (data) {
    //        this.role = data;
            
    //      }
    //    }
    //  );

    //console.log("getUser() " + this.authorizeService.getAccessToken() + this.authorizeService.getUser()[1]);
    //  console.log(this.userName);
    //}, 1000);

    //console.log("IsAuthenticated " + this.isAuthenticated);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
