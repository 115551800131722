import { Component, OnInit } from '@angular/core';
import { ApiDataService } from '../services/api-data.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: string;

  constructor(private apiDataService: ApiDataService) { }

  ngOnInit() {
    this.getNewsEdit();
  }

  getNewsEdit() {
    this.apiDataService.getNewsEdit().subscribe(
      data => {
        this.news = data.news;
      },
      (err) => {
        console.log(err);
      }
    )
  }

}
