import { Component, OnInit } from '@angular/core';
import { ApiDataService } from '../services/api-data.service';
import { INewsEdit } from '../interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
//import { Quill } from '../../../node_modules/quill';

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.css']
})
export class NewsEditorComponent implements OnInit {

  newsEdit: INewsEdit;
  news: string;

  //Sending modal
  displaySending: boolean = false;
  sendStatus: string = "";

  constructor(private apiDataService: ApiDataService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.userService.isAuthenticatedEmitted$.subscribe(
      data => {
        if (data == false) {
          this.router.navigate(['/home']);
        };
      }
    );

    this.getNewsEdit();

    //let font = this.quill.import('formats/font');

    //font.whitelist = ['inconsolata', 'roboto', 'mirza', 'arial'];
    //this.quill.register(font, true);
    
  }

  updateNewsEdit() {
    this.sendStatus = "sending";
    this.displaySending = true;
    this.newsEdit = { id: '12345', userName: 'jclarke', date: new Date(), news: this.news}

    this.apiDataService.updateNewsEdit(this.newsEdit).subscribe(
      data => {
        if (data) {
          setTimeout(() => {
            this.sendStatus = "complete";
          }, 2000);
         
        }
      }
    )
  }

  redirect() {
    this.displaySending = false;
    setTimeout(() => {
      this.router.navigate(['/news']);
    }, 1000);
    
  }

  getNewsEdit() {
    this.apiDataService.getNewsEdit().subscribe(
      data => {
        this.news = data.news;
      },
      (err) => {
        console.log(err);
      }
    )
  }

}
